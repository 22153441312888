<script>
import api from '@/command/api'
import moment from 'moment'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
export default {
  name: 'scenicAreas',
  data() {
    return api.command.getState()
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmTicketScenic/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '景点名称',
          key: 'name',
          type: 'input'
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'mainBodyName'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '景点名称',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img src={records.previewImg} />
                <div>{text}</div>
              </div>
            )
          },
          sorter: (a, b) => a.productName.length - b.productName.length
        },
        {
          dataIndex: 'linkShopName',
          title: '店铺名称',
          align: 'left',
          sorter: (a, b) => a.linkShopName.length - b.linkShopName.length
        },
        {
          dataIndex: 'readCount',
          title: '访问量',
          align: 'left',
          sorter: (a, b) => a.readCount - b.readCount
        },
        {
          dataIndex: 'saleCount',
          title: '销量',
          align: 'left',
          sorter: (a, b) => a.saleCount - b.saleCount
        },
        {
          dataIndex: 'hxCount',
          title: '核销量',
          align: 'left',
          sorter: (a, b) => a.hxCount - b.hxCount
        },
        {
          dataIndex: 'allStock',
          title: '库存',
          align: 'left',
          sorter: (a, b) => a.allStock - b.allStock
        },
        {
          dataIndex: 'unUsedStock',
          title: '剩余库存',
          align: 'left',
          sorter: (a, b) => a.unUsedStock - b.unUsedStock
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          onExport: records => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0'
            },
            {
              text: '下架',
              value: '1'
            }
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: records.upDown == 0 ? '下架' : '上架',
                dispaly: true,
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/farmTicketScenic/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`
                  })
                }
              },
              {
                name: '门票管理',
                dispaly: true,
                onClick: () =>
                  this.$router.push(
                    `/commodityManagement/scenicAreasTicket?id=${records.id}&shopId=${records.linkShopId}`
                  )
              },
              {
                name: '编辑',
                dispaly: true,
                onClick: () => this.$router.push(`/commodityManagement/scenicAreasDetail?id=${records.id}`)
              },
              {
                name: '删除',
                type: 'pop',
                dispaly: records.upDown == 1,
                popTitle: '是否确认删除?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/farmTicketScenic?id=${records.id}`
                  })
              }
            ].filter(e => e.dispaly)
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/commodityManagement/scenicAreasDetail')
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/farmTicketScenic/deleteBatch',
                  params: { idList }
                })
              }
            },
            {
              name: '批量上架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/farmTicketScenic/upBatch',
                  params: { idList }
                })
              }
            },
            {
              name: '批量下架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.edit.call(this, {
                  url: '/farmTicketScenic/downBatch',
                  params: { idList }
                })
              }
            }
          ]
        },
        {
          name: '页面设置',
          type: 'primary',
          ghost: true,
          onClick: () => this.$router.push('/commodityManagement/scenicAreasPageSetDetail')
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
</style>
